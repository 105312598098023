import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Modal from 'react-bootstrap/Modal';
import Heading from 'spotii-ui/Heading';
import Button from 'spotii-ui/Button';
import Form, { InputRow, FormRow } from 'spotii-ui/Form';
import LabelValue from 'components/LabelValue';
import message from 'spotii-ui/Message';
import { orderCapture } from 'api';
import { OrderType } from 'utils/types';

const OrderCapture = ({ order, show, onClose, onSuccess }) => {
  const { t } = useTranslation();
  const currLang = useSelector(state => state.language.language);
  const currentAmount = parseFloat(order.total);
  const formik = useFormik({
    initialValues: {
      amount: currentAmount.toFixed(2),
    },
    validationSchema: Yup.object().shape({
      amount: Yup.number()
        .max(currentAmount.toFixed(2), `${t('maxAmountIs')} ${currentAmount.toFixed(2)}`)
        .required(t('amountRequired')),
    }),
    onSubmit: async ({ amount }, actions) => {
      try {
        await orderCapture(order.id, amount);
        message.success(t('orderCaptureSuccess'));
        if (onSuccess) {
          onSuccess();
          actions.resetForm();
        }
      } catch (e) {
        console.warn(e);
        actions.setErrors({
          amount: (
            <span>
              {`${t('formErrorTryAgainContact')} `}
              <a href="mailto:">merchantsupport@spotii.me</a>
            </span>
          ),
        });
      }
    },
  });

  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    submitCount,
    submitForm,
  } = formik;

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    formik.resetForm();
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Body>
        <Heading h3>
          <p style={{ textAlign: `${currLang === 'ar' ? 'right' : 'left'}` }}>
            {t('captureOrder')}
          </p>
        </Heading>
        <p
          style={{ textAlign: `${currLang === 'ar' ? 'right' : 'left'}` }}
          className="color-black40 mb-40"
        >
          {t('captureOrderDesc')}
        </p>

        <Form onSubmit={handleSubmit}>
          <FormRow className="mb-40">
            <LabelValue label={t('orderReference')}>
              {order.displayReference || order.reference}
            </LabelValue>
          </FormRow>
          <InputRow
            label={`${t('captureAmount')} (${order.currency})`}
            name="amount"
            type="text"
            value={values.amount}
            onChange={handleChange}
            onBlur={handleBlur}
            error={submitCount && errors.amount && touched.amount ? errors.amount : null}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button type="primary" loading={isSubmitting} disabled={isSubmitting} onClick={submitForm}>
          {t('confirmCapture')}
        </Button>
        <Button type="default" onClick={handleClose} disabled={isSubmitting}>
          {t('cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

OrderCapture.propTypes = {
  order: PropTypes.shape(OrderType).isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
};

OrderCapture.defaultProps = {
  onSuccess: null,
};

export default OrderCapture;
