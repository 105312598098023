import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Select from 'react-select';
import Form, { FormRow, FormError, InputRow } from 'spotii-ui/Form';
import { InputPhone } from 'spotii-ui/Input';
import Button from 'spotii-ui/Button';
import authApi from 'api/auth';
import { setRegistrationFields } from 'reducers/registration';
import snakeToCamel from 'utils/snakeToCamel';
import sanitizePhone from 'utils/sanitizePhone';
import { PHONE_COUNTRY_SELECT_OPTIONS } from 'utils/constants';
import { prefixISDFromAlpha2 } from 'utils/countries';

const Personal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // const { userId } = useSelector(state => state.application.currentUser || {});
  const { lastName, firstName, phoneNumber } = useSelector(state => state.registration);
  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, t('atLeastTwoLetters'))
      .matches(/^([^0-9\u0660-\u0669]*)$/, {
        message: t('numbersNotAllowedInName'),
        excludeEmptyString: false,
      })
      .matches(/^([^\u0621-\u064A]*)$/, {
        message: t('englishNamesOnly'),
        excludeEmptyString: false,
      })
      .required(t('firstNameRequired')),
    lastName: Yup.string()
      .min(2, t('atLeastTwoLetters'))
      .matches(/^([^0-9\u0660-\u0669]*)$/, {
        message: t('numbersNotAllowedInName'),
        excludeEmptyString: false,
      })
      .matches(/^([^\u0621-\u064A]*)$/, {
        message: t('englishNamesOnly'),
        excludeEmptyString: false,
      })
      .required(t('lastNameRequired')),
    phoneNumber: Yup.string()
      .matches(InputPhone.validateRegExp, t('phoneNotVaild'))
      .required(t('phoneNumberRequired')),
  });
  const processErrors = e => {
    let result = {};
    const { errors } = e;
    const processFields = obj =>
      Object.keys(obj).reduce((res, key) => {
        // Process profile as set of own errors
        if (key === 'profile') {
          return {
            ...res,
            ...processFields(obj[key]),
          };
        }

        res[snakeToCamel(key)] = Array.isArray(obj[key]) ? obj[key][0] : obj[key];
        return res;
      }, {});

    if (errors) {
      result = processFields(errors);

      if (errors.code === 'user_already_exists') {
        result.phoneNumber = t('alreadyHaveAccountWithThisPhoneNumber');
      }
    }

    return result;
  };

  const [selectedCountry, setSelectedCountry] = React.useState('');

  const onCountrySelectChange = e => {
    setSelectedCountry(e.value);
  };
  const prepareData = values => {
    let phone = values.phoneNumber.replace(/^0/, '');
    phone = `${prefixISDFromAlpha2(selectedCountry)}${phone}`;

    return {
      ...values,
      phoneNumber: phone,
    };
  };

  const formik = useFormik({
    initialValues: {
      lastName,
      firstName,
      phoneNumber: phoneNumber ? sanitizePhone(phoneNumber) : '',
    },
    validationSchema,
    onSubmit: async (values, actions) => {
      try {
        const data = prepareData(values);
        await authApi.registrationUpdate(data);
        await authApi.registrationValidate();
        // TODO: We should analyze response from validation ?..

        // Save current fields in the memory. Phone number in international format
        dispatch(
          setRegistrationFields({
            ...values,
            phoneNumber: data.phoneNumber,
          }),
        );
        actions.setStatus('success');
      } catch (e) {
        console.warn(e);
        actions.setErrors(processErrors(e));
      }
    },
  });

  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    submitCount,
  } = formik;

  if (formik.status === 'success') {
    return <Redirect to="/signup/verify" push />;
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Row className="form__cols">
        <Col sm={6} className="form__col">
          <InputRow
            label={t('firstName')}
            name="firstName"
            type="text"
            value={values.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
            error={submitCount && errors.firstName && touched.firstName ? errors.firstName : null}
          />
        </Col>
        <Col className="d-sm-none mb-3" />
        <Col sm={6} className="form__col">
          <InputRow
            label={t('lastName')}
            name="lastName"
            type="text"
            value={values.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
            error={submitCount && errors.lastName && touched.lastName ? errors.lastName : null}
          />
        </Col>
      </Row>
      <FormRow>
        <Row className="form__cols">
          <Col
            xs={4}
            sm={4}
            md={4}
            style={{ paddingRight: 0 }}
            className="form__col"
            id="countrySelectContainer"
          >
            <Select
              options={PHONE_COUNTRY_SELECT_OPTIONS}
              placeholder={PHONE_COUNTRY_SELECT_OPTIONS[0].label}
              onChange={onCountrySelectChange}
              controlShouldRenderValue
            />
          </Col>
          <Col xs={8} sm={8} md={8} style={{ paddingLeft: 2, paddingRight: 0 }}>
            <InputPhone
              country={selectedCountry || 'AE'}
              label={t('mobileNumber')}
              name="phoneNumber"
              type="tel"
              value={values.phoneNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.phoneNumber && touched.phoneNumber ? errors.phoneNumber : null}
            />
          </Col>
        </Row>
        {errors.phoneNumber && touched.phoneNumber ? (
          <FormError>{errors.phoneNumber}</FormError>
        ) : null}
      </FormRow>
      <FormRow type="submit">
        <Button
          htmlType="submit"
          size="large"
          type="primary"
          block
          loading={isSubmitting}
          disabled={isSubmitting}
        >
          {t('continue')}
        </Button>
      </FormRow>
    </Form>
  );
};

export default Personal;
