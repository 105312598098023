import React from 'react';
import { Link } from 'react-router-dom';
import { LocationType } from '../../utils/types';
import './OtpControl.scss';

const OtpControl = ({ toChangePhone }) => (
  <div className="otp-control">
    <p>Didn’t get a code?</p>
    <p className="otp-contol__actions">
      {/* <span className="link">Resend code</span> */}
      {/* <br /> */}
      {toChangePhone ? <Link to={toChangePhone}>Change number</Link> : null}
    </p>
  </div>
);

OtpControl.propTypes = {
  toChangePhone: LocationType.isRequired,
};

export default OtpControl;
