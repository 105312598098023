import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Form, { FormRow, FormError } from 'spotii-ui/Form';
import Button from 'spotii-ui/Button';
import { useTranslation } from 'react-i18next';
import OtpInput from './OtpInput';
import './OtpForm.scss';

const OtpForm = ({ defaultValue, onSubmit, loading, error }) => {
  const [otp, setOtp] = useState(defaultValue);
  const [disabled, setDisabled] = useState(true);
  const { t } = useTranslation();
  const handleSubmit = (e, value) => {
    if (e) {
      e.preventDefault();
    }
    if (loading) {
      return;
    }

    if (onSubmit) {
      onSubmit(value || otp);
    }
  };

  const handleInput = e => {
    const { value } = e.target;
    setDisabled(value.length < 4);
    setOtp(value);
    if (value.length === 4) {
      handleSubmit(null, value);
    }
  };

  return (
    <div className="otp-form">
      <Form onSubmit={handleSubmit}>
        <FormRow>
          <OtpInput value={otp} onChange={handleInput} autofocus />
          {error ? (
            <div className="text_align_center">
              <FormError>Code is incorrect</FormError>
            </div>
          ) : null}
        </FormRow>
        <FormRow type="submit">
          <Button
            htmlType="submit"
            size="large"
            type="primary"
            loading={loading}
            disabled={disabled || loading}
            block
          >
            {t('continue')}
          </Button>
        </FormRow>
      </Form>
    </div>
  );
};

OtpForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.bool,
};

OtpForm.defaultProps = {
  defaultValue: '',
  loading: false,
  error: false,
};

export default OtpForm;
