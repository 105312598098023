/* eslint-disable camelcase */
/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import Input from 'spotii-ui/Input';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Currency from 'spotii-ui/Currency';
import { DateRangePicker } from 'components/DatePicker';
import TableView from 'components/TableView/TableView';
import Pagination from 'components/Pagination';
import OrderStatus from 'components/OrderStatus';
import OrderActions from 'components/OrderActions';
import { fetchOrders, changeDates, changePage, changeQueryFilter, reset } from 'reducers/orders';
import {
  ORDER_STATUS_OPENED,
  ORDER_STATUS_COMPLETED,
  ORDER_STATUS_VOIDED,
  ORDER_STATUS_REFUNDED,
  ORDER_STATUS_PARTIALLY_REFUNDED,
  PLAN_SLUGS,
  IM_DISPLAY_NAME,
} from 'utils/constants';

import './OrdersList.scss';

const ORDERS_PER_PAGE = 10;

//
// Component
//
const OrdersList = ({ ordersType, appState }) => {
  let userCanRefund = true;
  const userHasRole = appState.loggedEmployee.role;
  if (userHasRole) {
    const userPermissions = appState.loggedEmployee.role.permissions;
    userCanRefund = userPermissions.includes('orders_write');
  }

  const { displayName, isUserFeeForMerchant } = appState;
  const requireAccountName = displayName === IM_DISPLAY_NAME;

  const dispatch = useDispatch();
  const {
    isLoading,
    isResolved,
    list,
    search,
    fromDate: stateFromDate,
    toDate: stateToDate,
    pageCount,
    currentPage,
  } = useSelector(state => state.orders);

  const currLang = useSelector(state => state.language.language);
  const { t } = useTranslation();

  // Columns render options
  const columns = [
    {
      title: <div className={`column-title-${currLang}`}>{t('date')}</div>,
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: value => (
        <div className={`line-height column-render-${currLang}`}>
          <div
            className="orders-list__date"
            title={moment.parseZone(value).format('YYYY-MM-DD HH:mm:ss')}
          >
            {moment.parseZone(value).format('YYYY-MM-DD')}
          </div>
        </div>
      ),
    },
    {
      title: <div className={`column-title-${currLang}`}>{t('status')}</div>,
      dataIndex: 'status',
      key: 'status',
      render: value => (
        <div className={`line-height column-render-${currLang}`}>
          <OrderStatus status={value} badge />
        </div>
      ),
    },
    {
      title: <div className={`column-title-${currLang}`}>{t('reference')}</div>,
      dataIndex: 'orderNumber',
      key: 'orderNumber',
      render: (value, row) => {
        const { reference, displayReference } = row;
        return (
          <div className={`line-height column-render-${currLang}`}>
            {reference && displayReference && reference !== displayReference ? (
              <>
                <div>{`${t('ref')} ${reference}`}</div>
                <div>
                  <bdi className="orders-list__sub">{`${t('displayRef')} ${displayReference}`}</bdi>
                </div>
              </>
            ) : (
              <div>{reference || displayReference}</div>
            )}
            <span className="orders-list__sub">
              {currLang !== 'ar' && <bdi>{t('spotiiID')}</bdi>}
              <bdi>{` ${value.toUpperCase()} `}</bdi>
              {currLang === 'ar' && <bdi>{t('spotiiID')}</bdi>}
            </span>
          </div>
        );
      },
    },
    {
      title: <div className={`column-title-${currLang}`}>{t('customer')}</div>,
      dataIndex: 'customer',
      key: 'customer',
      render: value => (
        <div className={`line-height column-render-${currLang}`}>
          <div>
            {value.firstName} {value.lastName}
          </div>
          <div className="orders-list__sub">{value.email}</div>
        </div>
      ),
    },
    {
      ...(requireAccountName && {
        title: <div className={`column-title-${currLang}`}>{t('accountName')}</div>,
        dataIndex: 'consumerInfo',
        key: 'consumerInfo',
        render: value => {
          return (
            <div className={`line-height column-render-${currLang}`}>
              <div>
                {value.firstName} {value.lastName}
              </div>
            </div>
          );
        },
      }),
    },
    {
      title: <div className={`column-title-${currLang}`}>{t('total')}</div>,
      dataIndex: 'total',
      key: 'total',
      render: (value, row) => (
        <div className={`line-height column-render-${currLang}`}>
          <Currency currency={row.currency} amount={value} />
        </div>
      ),
    },
    {
      title: <div className={`column-title-${currLang}`}>{t('userFee')}</div>,
      dataIndex: 'userFee',
      key: 'userFee',
      render: (value, row) => (
        <div className={`line-height column-render-${currLang}`}>
          <Currency currency={row.currency} amount={value} />
        </div>
      ),
    },
    {
      title: <div className={`column-title-${currLang}`}>{t('refunded')}</div>,
      dataIndex: 'totalRefund',
      key: 'totalRefund',
      render: (value, row) => {
        return value ? (
          <div className={`line-height column-render-${currLang}`}>
            <Currency currency={row.currency} amount={value} />
          </div>
        ) : (
          <div className={`column-status-${currLang}`}>-</div>
        );
      },
    },
    {
      title: <div className={`column-title-${currLang}`}>{t('type')}</div>,
      dataIndex: 'planId',
      key: 'planId',
      render: value => {
        let name = '';
        if (value) {
          const { planDictionary } = appState;
          const plan = planDictionary.find(pd => pd.plan_id === value);
          if (plan.slug === PLAN_SLUGS.PAY_NOW) {
            name = t('payNowType');
          } else {
            name = t('instalmentsType');
          }
        }

        return (
          <div className={`line-height column-render-${currLang}`}>
            <div>{name}</div>
          </div>
        );
      },
    },
  ];

  // dates should be a MomentJS instance
  const initialStartDate = stateFromDate ? moment(stateFromDate) : null;
  const initialEndDate = stateToDate ? moment(stateToDate) : null;

  const [dateFocus, setDateFocus] = useState(null);
  const [orderFilter, setOrderFilter] = useState(null);
  const [prevOrderFilter, setPrevOrderFilter] = useState(null);
  const [fromDate, setFromDate] = useState(initialStartDate);
  const [toDate, setToDate] = useState(initialEndDate);

  const cls = b.with('orders-list');
  const page = Math.max(currentPage, 1) - 1;
  const fetchParams = {
    fromDate: stateFromDate,
    toDate: stateToDate,
    search: orderFilter,
    limit: ORDERS_PER_PAGE,
    page: currentPage,
  };

  if (ordersType === 'completed') {
    fetchParams.status = [ORDER_STATUS_COMPLETED, ORDER_STATUS_PARTIALLY_REFUNDED];
  } else if (ordersType === ORDER_STATUS_REFUNDED.toLowerCase()) {
    fetchParams.status = ORDER_STATUS_REFUNDED;
  } else if (ordersType === ORDER_STATUS_OPENED.toLowerCase()) {
    fetchParams.status = ORDER_STATUS_OPENED;
  } else if (ordersType === ORDER_STATUS_VOIDED.toLowerCase()) {
    fetchParams.status = ORDER_STATUS_VOIDED;
  }

  // handlers
  const changeOrderFilter = () => {
    if (!prevOrderFilter || (prevOrderFilter && prevOrderFilter !== orderFilter)) {
      dispatch(changeQueryFilter({ search: orderFilter, currentPage: 1 }));
      setPrevOrderFilter(orderFilter);
    } else {
      dispatch(changeQueryFilter({ search: orderFilter, currentPage }));
    }
  };
  const onPageChange = p => dispatch(changePage(p.selected + 1));
  const handleOrderActionSuccess = () =>
    // Resend fetch request with same params
    dispatch(fetchOrders(fetchParams));

  // Add Actions column to the table view
  let columnsExtended = columns.slice();
  if (appState.allowNoCallBackUrls) {
    columnsExtended.push({
      title: <div className={`column-title-${currLang}`}>{t('advisor')}</div>,
      dataIndex: 'employeeName',
      key: 'employeeName',
      render: value => (
        <div className={`column-status-${currLang}`}>
          <span>{value}</span>
        </div>
      ),
    });
  }
  columnsExtended.push({
    title: t('action'),
    dataIndex: '',
    key: 'actions',
    render: (value, row) => (
      <div className={`column-button-${currLang}`}>
        <OrderActions
          ordersType={ordersType}
          order={row}
          onActionSuccess={handleOrderActionSuccess}
          userCanRefund={userCanRefund}
        />
      </div>
    ),
  });

  if (!isUserFeeForMerchant) {
    columnsExtended = columnsExtended.filter(obj => obj.key !== 'userFee');
  }

  // Reset reducer on page view
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => dispatch(reset()), []);

  // Fetch orders on first render and if one of the listed parameters changed.
  useEffect(() => {
    dispatch(fetchOrders(fetchParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordersType, search, stateFromDate, stateToDate, currentPage]);

  return (
    <div className={cls()}>
      <div className={cls('controls')}>
        <DateRangePicker
          // Allow to select only one date
          t={t}
          currLang={currLang}
          minimumNights={0}
          numberOfMonths={1}
          hideKeyboardShortcutsPanel
          startDateId="startDate"
          endDateId="endDate"
          startDate={fromDate}
          endDate={toDate}
          onDatesChange={({ startDate, endDate }) => {
            setFromDate(startDate);
            setToDate(endDate);
          }}
          focusedInput={dateFocus}
          onFocusChange={focusedInput => {
            setDateFocus(focusedInput);
          }}
          customArrowIcon="–"
          // Allow to select any date
          isOutsideRange={() => {}}
          onClose={({ startDate, endDate }) => {
            dispatch(
              changeDates({
                fromDate: startDate ? startDate.format('YYYY-MM-DD') : null,
                toDate: endDate ? endDate.format('YYYY-MM-DD') : null,
              }),
            );
          }}
        />
        <Input
          label={t('search')}
          placeholder={t('orderListSearch')}
          name="Search"
          style={{
            marginLeft: 3,
            marginRight: 3,
            lineHeight: '20px',
            paddingTop: '23px',
            paddingLeft: '15px',
            paddingBottom: '10px',
          }}
          value={orderFilter}
          onChange={e => setOrderFilter(e.target.value)}
          onKeyPress={e => (e.key === 'Enter' ? changeOrderFilter() : null)}
        />
      </div>
      <TableView columns={columnsExtended} data={list} loading={isLoading} />
      {isResolved && list.length ? (
        <div className={cls('pagination')}>
          <Pagination current={page} count={pageCount} onChange={onPageChange} />
        </div>
      ) : null}
    </div>
  );
};

OrdersList.propTypes = {
  ordersType: PropTypes.oneOf(['all', 'completed', 'refunded', 'opened', 'voided']),
  appState: PropTypes.objectOf(PropTypes.objectt).isRequired,
};

OrdersList.defaultProps = {
  ordersType: null,
};

export default OrdersList;
