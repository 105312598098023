import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Heading from 'spotii-ui/Heading';
import Paper from 'auth/Paper';
import BlockHead from 'auth/BlockHead';
import { useTranslation } from 'react-i18next';
import { CompanyContactsForm } from 'auth/Registration';

export default function Profile() {
  const { isPopulated } = useSelector(state => state.registration);
  const { t } = useTranslation();
  if (!isPopulated) {
    return <Redirect to="/signup" />;
  }

  return (
    <div className="page-transition">
      <Paper size="small">
        <BlockHead>
          <Heading h1>{t('companyContacts')}</Heading>
          <p>{t('companyContactsDesc')}</p>
        </BlockHead>
        <CompanyContactsForm />
      </Paper>
    </div>
  );
}
