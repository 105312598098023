import moment from 'moment';
import React from 'react';
import Flag from 'spotii-ui/Flag';

export const ORDER_STATUS_OPENED = 'OPENED';
export const ORDER_STATUS_COMPLETED = 'COMPLETED';
export const ORDER_STATUS_REFUNDED = 'REFUNDED';
export const ORDER_STATUS_PARTIALLY_REFUNDED = 'PARTIALLY_REFUNDED';
export const ORDER_STATUS_VOIDED = 'VOIDED';

export const CHECKOUT_STATUS_OPEN = 'OPEN';
export const CHECKOUT_STATUS_DRAFT = 'DRAFT';
export const CHECKOUT_STATUS_EXPIRED = 'EXPIRED';
export const CHECKOUT_STATUS_SUBMITTED = 'SUBMITTED';
export const CHECKOUT_STATUS_FAILED = 'FAILED';
export const CHECKOUT_STATUS_NULL = 'null';

export const PLAN_SLUGS = {
  BI_WEEKLY: 'bi-weekly',
  ZERO_DOWN_BI_WEEKLY: 'zero-downpayment-bi-weekly',
  MONTHLY: 'monthly',
  PAY_NOW: 'pay-now',
  MONTHLY_10_SPLITS: 'monthly-10-splits',
};

export const PLAN_RATE_SLUGS = {
  INSTALLMENTS: 'installments',
  PAY_NOW: 'pay_now',
};

export const DRAFT_STATUS_REJECTED = 'REJECTED';
export const DRAFT_STATUS_COMPLETED = 'COMPLETED';
export const DRAFT_STATUS_PENDING_MERCHANT = 'PENDING_MERCHANT';
export const DRAFT_STATUS_PENDING_PAYMENT = 'PENDING_PAYMENT';
export const DRAFT_STATUS_CANCELLED = 'CANCELLED';

export const CHECKOUT_SOURCE_NON_INSTORE = 'NON_INSTORE';
export const CHECKOUT_SOURCE_INSTORE = 'INSTORE';
export const CHECKOUT_SOURCE_PL = 'payment_link';

export const COUNTRY_SELECT_OPTIONS = [
  { value: 'SA', label: 'Saudi Arabia' },
  { value: 'AE', label: 'United Arab Emirates' },
  { value: 'BH', label: 'Bahrain' },
  // { value: 'KW', label: 'Kuwait' },
  { value: 'OM', label: 'Oman' },
  { value: 'PK', label: 'Pakistan' },
  { value: 'QA', label: 'Qatar' },
];

export const NO_IBAN_COUNTRIES = ['OM'];

export const PHONE_COUNTRY_SELECT_OPTIONS = [
  {
    value: 'AE',
    label: (
      <>
        <Flag country="AE" />
        {' +971'}
      </>
    ),
  },
  {
    value: 'SA',
    label: (
      <>
        <Flag country="SA" />
        {' +966'}
      </>
    ),
  },
  {
    value: 'BH',
    label: (
      <>
        <Flag country="BH" />
        {' +973'}
      </>
    ),
  },
  // {
  //   value: 'KW',
  //   label: (
  //     <>
  //       <Flag country="KW" />
  //       {' +965'}
  //     </>
  //   ),
  // },
  {
    value: 'OM',
    label: (
      <>
        <Flag country="OM" />
        {' +968'}
      </>
    ),
  },
  {
    value: 'QA',
    label: (
      <>
        <Flag country="QA" />
        {' +974'}
      </>
    ),
  },
];

export const RESOURCES = [
  {
    name: 'Admin',
    path: '/admin',
    actions: [{ key: 'Access', value: 'read' }],
  },
  {
    name: 'Settings',
    path: '/settings',
    actions: [{ key: 'Access', value: 'read' }],
  },
  {
    name: 'Checkouts',
    path: '/checkout',
    actions: [
      { key: 'Consult', value: 'read' },
      { key: 'Consult all', value: 'readall' },
      { key: 'Create', value: 'write' },
    ],
  },
  {
    name: 'Dashboard',
    path: '/',
    actions: [{ key: 'Access', value: 'read' }],
  },
  {
    name: 'Orders',
    path: '/orders',
    actions: [
      { key: 'Consult', value: 'read' },
      { key: 'Refund', value: 'write' },
    ],
  },
  {
    name: 'Reports',
    path: '/reports',
    actions: [{ key: 'Access', value: 'read' }],
  },
];

// Columns render options
export const EMPLOYEE_COLUMNS = [
  {
    title: 'First name',
    dataIndex: 'first_name',
    key: 'first_name',
    render: value => <span>{value}</span>,
  },
  {
    title: 'Last name',
    dataIndex: 'last_name',
    key: 'last_name',
    render: value => <span>{value}</span>,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    render: value => <span>{value}</span>,
  },
  {
    title: 'Added at',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: value => (
      <div className="checkouts-list__date" title={moment(value).format('YYYY-MM-DD HH:mm:ss')}>
        {moment(value).format('YYYY-MM-DD')}
      </div>
    ),
  },
];

export const IM_DISPLAY_NAME = 'InsuranceMarket.ae™';

export const AVAILABLE_ITEMS = [
  { value: 5425, label: 'IPhone 15 Pro Max' },
  { value: 4299, label: 'IPhone 15 Pro' },
  { value: 3799, label: 'IPhone 15 Plus' },
  { value: 3399, label: 'IPhone 15' },
  { value: 3900, label: 'IPhone 14 Pro Max' },
  { value: 4140, label: 'IPhone 14 Pro' },
  { value: 2899, label: 'IPhone 14 Plus' },
  { value: 2950, label: 'IPhone 14' },
  { value: 4949, label: 'Samsung Galaxy S23 Ultra' },
  { value: 3899, label: 'Samsung Galaxy S23+' },
  { value: 3199, label: 'Samsung Galaxy S23' },
  { value: 3197, label: 'Samsung Galaxy S22 Ultra' },
  { value: 2789, label: 'Samsung Galaxy S22+' },
  { value: 2099, label: 'Samsung Galaxy S22' },
  { value: 1970, label: 'Samsung Galaxy S21 Ultra' },
  { value: 1549, label: 'Samsung Galaxy S21+' },
  { value: 1380, label: 'Samsung Galaxy S21' },
];
