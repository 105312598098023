import React from 'react';
import PropTypes from 'prop-types';
import { Switch, useRouteMatch, useLocation } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Layout from 'auth/Layout';
import TopBar from 'auth/TopBar';
import RouteUnauthenticated from 'components/RouteUnauthenticated';
import RouteBinded from 'components/RouteBinded';
import RoutePrivate from 'components/RoutePrivate';
import { AppStateType } from 'reducers/application';

// Sub-routes
import CreateAccount from './CreateAccount';
import CompanyContacts from './CompanyContacts';
import CompanyDetails from './CompanyDetails';
import PersonalDetails from './PersonalDetails';
import Verify from './Verify';
import Welcome from './Welcome';

export default function SignUp({ appState }) {
  const { path } = useRouteMatch();
  const location = useLocation();

  return (
    <Layout>
      <TopBar authOption="login" />
      <Layout.Container>
        <Layout.ContainerInner>
          <TransitionGroup component={null}>
            <CSSTransition key={location.key} classNames="fade" timeout={300}>
              <Switch location={location}>
                <RouteUnauthenticated
                  path={path}
                  exact
                  component={CreateAccount}
                  appState={appState}
                />
                <RouteUnauthenticated
                  path={`${path}/company-contacts`}
                  exact
                  component={CompanyContacts}
                  appState={appState}
                />
                <RouteUnauthenticated
                  path={`${path}/company-details`}
                  exact
                  component={CompanyDetails}
                  appState={appState}
                />
                <RouteUnauthenticated
                  path={`${path}/personal-details`}
                  exact
                  component={PersonalDetails}
                  appState={appState}
                />
                <RouteBinded path={`${path}/verify`} exact component={Verify} appState={appState} />
                <RoutePrivate
                  path={`${path}/welcome`}
                  exact
                  component={Welcome}
                  appState={appState}
                />
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        </Layout.ContainerInner>
      </Layout.Container>
    </Layout>
  );
}

SignUp.propTypes = {
  appState: PropTypes.shape(AppStateType).isRequired,
};
