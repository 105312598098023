/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getOrdersList } from 'api';
import { ordersListSerializer } from './serializers';

const ordersInitialState = {
  isLoading: false,
  isResolved: false,
  fromDate: null,
  toDate: null,
  search: null,
  pageCount: 0,
  pages: {},
  currentPage: null,
  list: [],
};

const orders = createSlice({
  name: 'orders',
  initialState: ordersInitialState,
  reducers: {
    fetchOrdersStart(state) {
      state.isLoading = true;
    },
    fetchOrdersSuccess(state, { payload }) {
      const { data } = payload;
      return {
        ...state,
        isLoading: false,
        isResolved: true,
        ...payload,
        list: ordersListSerializer(data),
      };
    },
    changeQueryFilter(state, { payload }){
      const { search, currentPage } = payload;
      return {
        ...state,
        search,
        currentPage,
      }
    },
    fetchOrdersFail(state) {
      state.isLoading = false;
      state.isResolved = true;
    },
    changeDates(state, { payload }) {
      const { fromDate, toDate } = payload;
      return {
        ...state,
        fromDate,
        toDate,
      };
    },
    changePage(state, { payload }) {
      state.currentPage = payload;
    },
    reset() {
      return ordersInitialState;
    },
  },
});

export default orders.reducer;

export const {
  fetchOrdersStart,
  fetchOrdersSuccess,
  fetchOrdersFail,
  changeQueryFilter,
  changeDates,
  changePage,
  reset,
} = orders.actions;

// { fromDate, toDate, search, limit, page, status }
export const fetchOrders = params => async (dispatch, state) => {
  if (state.isLoading) {
    return;
  }
  dispatch(fetchOrdersStart());
  try {
    const response = await getOrdersList(params);
    const { fromDate, toDate, search } = params;
    dispatch(
      fetchOrdersSuccess({
        fromDate,
        toDate,
        search,
        ...response,
      }),
    );
  } catch (e) {
    dispatch(fetchOrdersFail(e));
  }
};
