/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';
import authApi from 'api/auth';
import buildLogoutUrl from 'utils/buildLogoutUrl';
import { patchSettlementEmails } from 'api';
import { userSerializer, applicationSerializer } from './serializers';

export const AppStateType = {
  isAuthStatusResolved: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isSidebarOpened: PropTypes.bool.isRequired,

  currentUser: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    userId: PropTypes.string,
    profileId: PropTypes.string,
    registrationStatus: PropTypes.string,
    dateOfBirth: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
  }).isRequired,
};

const appInitialState = {
  isAuthStatusResolved: false,
  isFlagMerchantLoading: false,
  isAuthenticated: false,
  isSidebarOpened: false,
  currentUser: {
    firstName: null,
    lastName: null,
    userId: null,
    profileId: null,
    registrationStatus: null,
    dateOfBirth: null,
    email: null,
    phoneNumber: null,
  },
  logoutUrl: '',
  settlementEmails: [],
};

const application = createSlice({
  name: 'application',
  initialState: appInitialState,
  reducers: {
    fetchMerchantFlagSuccess(state, { payload }) {
      return {
        ...state,
        ...applicationSerializer(payload),
        isFlagMerchantLoading: false,
      };
    },
    editSettlementEmailsSuccess(state, { payload }) {
      return {
        ...state,
        settlementEmails: payload,
      };
    },
    fetchMerchantFlagStart(state) {
      state.isFlagMerchantLoading = true;
    },
    authStatusChange(state, { payload }) {
      state.isAuthStatusResolved = true;
      state.isAuthenticated = !!payload;
      state.currentUser = userSerializer(payload);
      state.logoutUrl = buildLogoutUrl();
    },
    openSidebar(state) {
      state.isSidebarOpened = true;
    },
    closeSidebar(state) {
      state.isSidebarOpened = false;
    },
  },
});

export const {
  authStatusChange,
  openSidebar,
  closeSidebar,
  fetchMerchantFlagSuccess,
  fetchMerchantFlagStart,
  editSettlementEmailsSuccess,
} = application.actions;

export const refreshUserProfile = () => async dispatch => {
  try {
    const currentUser = await authApi.getProfile();
    dispatch(authStatusChange(currentUser));
  } catch (e) {
    console.warn('Failed to refresh users profile information.');
  }
};

export const editSettlementEmails = payload => async (dispatch, getState) => {
  const state = getState();
  const { email, isDelete } = payload;

  const { merchantId } = state.application.currentUser.merchant;
  const { settlementEmails } = state.application;

  let newEmails = [];

  if (isDelete) {
    newEmails = settlementEmails.filter(settlementEmail => {
      return settlementEmail !== email;
    });
  } else {
    newEmails = [...settlementEmails, email];
  }

  try {
    const resp = await patchSettlementEmails(merchantId, newEmails);
    dispatch(editSettlementEmailsSuccess(resp.settlement_emails));
  } catch (e) {
    console.error(e);
  }
};

export default application.reducer;
