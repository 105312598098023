import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Select from 'react-select';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form, { FormRow, FormError, InputRow } from 'spotii-ui/Form';
import { InputPhone } from 'spotii-ui/Input';
import Button from 'spotii-ui/Button';
import Heading from 'spotii-ui/Heading';
import authApi from 'api/auth';
import { setRegistrationFields, setCountry } from 'reducers/registration';
import snakeToCamel from 'utils/snakeToCamel';
import Mailcheck from 'react-mailcheck';
import './Registration.scss';
import { PHONE_COUNTRY_SELECT_OPTIONS } from 'utils/constants';
import sanitizePhone from 'utils/sanitizePhone';
import { prefixISDFromAlpha2 } from '../../utils/countries';

const CompanyContactsForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const processErrors = e => {
    let result = {};
    const { errors } = e;
    const processFields = obj =>
      Object.keys(obj).reduce((res, key) => {
        // Process profile as set of own errors
        if (key === 'profile') {
          return {
            ...res,
            ...processFields(obj[key]),
          };
        }

        res[snakeToCamel(key)] = Array.isArray(obj[key]) ? obj[key][0] : obj[key];
        return res;
      }, {});

    if (errors) {
      result = processFields(errors);

      if (errors.code === 'user_already_exists') {
        result.phoneNumber = t('alreadyHaveAccountWithThisPhoneNumber');
      }
    }

    return result;
  };

  const validationSchema = Yup.object().shape({
    legalBusinessName: Yup.string().required(t('businessNameRequired')),
    businessSupportPhoneNumber: Yup.string()
      .matches(InputPhone.validateRegExp, t('phoneNotVaild'))
      .required(t('supportNumberRequired')),
    businessSupportEmail: Yup.string()
      .email(t('somethingWrongEmail'))
      .required(t('supportEmailRequired')),
    // Business address
    line1: Yup.string().required(t('addressNameRequired')),
    line2: Yup.string(),
    city: Yup.string().required(t('cityIsRequired')),
    countryId: Yup.string().required(t('countryRequired')),
    postcode: Yup.string().required(t('poBoxRequired')),
  });

  const {
    legalBusinessName,
    businessSupportPhoneNumber,
    businessSupportEmail,
    line1,
    line2,
    city,
    postcode,
  } = useSelector(state => state.registration);

  const [selectedCountry, setSelectedCountry] = React.useState('AE');

  const onCountrySelectChange = e => {
    setSelectedCountry(e.value);
  };
  const prepareData = values => {
    let phoneNumber = values.businessSupportPhoneNumber;
    phoneNumber = phoneNumber.replace(/^0/, '');
    phoneNumber = `${prefixISDFromAlpha2(selectedCountry)}${phoneNumber}`;

    return {
      ...values,
      countryId: selectedCountry,
      businessSupportPhoneNumber: phoneNumber,
    };
  };

  const formik = useFormik({
    initialValues: {
      legalBusinessName,
      businessSupportEmail,
      line1,
      line2,
      city,
      postcode,
      countryId: 'United Arab Emirates',
      businessSupportPhoneNumber: businessSupportPhoneNumber
        ? sanitizePhone(businessSupportPhoneNumber)
        : '',
    },
    validationSchema,
    onSubmit: async (values, actions) => {
      try {
        const data = prepareData(values);
        await authApi.registrationUpdate(data);
        // Save current fields in the memory. Phone number in international format
        dispatch(
          setRegistrationFields({
            ...values,
            businessSupportPhoneNumber: data.supportNumber,
          }),
        );
        dispatch(
          setCountry({
            ...values,
            country: selectedCountry,
          }),
        );
        actions.setStatus('success');
      } catch (e) {
        console.warn(e);
        actions.setErrors(processErrors(e));
      }
    },
  });

  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    submitCount,
  } = formik;

  const handleClickMailChange = suggestion => {
    values.businessSupportEmail = suggestion.full;
    document.getElementsByName('businessSupportEmail')[0].focus();
  };

  if (formik.status === 'success') {
    return <Redirect to="/signup/company-details" push />;
  }

  const showError = (field, afterSubmit) =>
    (!afterSubmit || submitCount) && errors[field] && touched[field] ? errors[field] : null;

  return (
    <Form onSubmit={handleSubmit}>
      <InputRow
        label={t('businessLegalName')}
        name="legalBusinessName"
        type="text"
        value={values.legalBusinessName}
        onChange={handleChange}
        onBlur={handleBlur}
        error={showError('legalBusinessName', true)}
      />
      <FormRow>
        <Row className="form__cols">
          <Col
            xs={4}
            sm={4}
            md={4}
            // style={{ paddingRight: 0 }}
            className="form__col"
            id="countrySelectContainer"
          >
            <Select
              options={PHONE_COUNTRY_SELECT_OPTIONS}
              placeholder={PHONE_COUNTRY_SELECT_OPTIONS[0].label}
              onChange={onCountrySelectChange}
              controlShouldRenderValue
            />
          </Col>
          <Col xs={8} sm={8} md={8} style={{ paddingLeft: 2, paddingRight: 0 }}>
            <InputPhone
              label={t('supportPhoneNumber')}
              name="businessSupportPhoneNumber"
              type="tel"
              country={selectedCountry || 'AE'}
              value={values.businessSupportPhoneNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              error={showError('businessSupportPhoneNumber')}
            />
          </Col>
        </Row>
        {errors.businessSupportPhoneNumber && touched.businessSupportPhoneNumber ? (
          <FormError>{errors.businessSupportPhoneNumber}</FormError>
        ) : null}
      </FormRow>
      <Mailcheck email={values.businessSupportEmail}>
        {suggestion => (
          <>
            <InputRow
              label={t('supportEmail')}
              name="businessSupportEmail"
              type="email"
              value={values.businessSupportEmail}
              onChange={handleChange}
              onBlur={handleBlur}
              error={showError('businessSupportEmail', true)}
            />
            {suggestion && (
              <div style={{ fontSize: 14, textAlign: 'right' }}>
                {t('didYouMeanEmail')}{' '}
                <Button
                  type="button"
                  style={{ color: 'red', fontSize: 14, paddingLeft: 0, paddingRight: 0 }}
                  onMouseDown={() => handleClickMailChange(suggestion)}
                >
                  {suggestion.full}
                </Button>
                {t('questionMark')}
              </div>
            )}
          </>
        )}
      </Mailcheck>
      <FormRow type="submit">
        <Heading h3>{t('billingAddress')}</Heading>
      </FormRow>

      <InputRow
        label={t('buildingStreetArea')}
        name="line1"
        type="text"
        value={values.line1}
        onChange={handleChange}
        onBlur={handleBlur}
        error={showError('line1', true)}
      />
      <InputRow
        label={t('unitNumber')}
        name="line2"
        type="text"
        value={values.line2}
        onChange={handleChange}
        onBlur={handleBlur}
        error={showError('line2', true)}
      />
      <InputRow
        label={t('city')}
        name="city"
        type="text"
        value={values.city}
        onChange={handleChange}
        onBlur={handleBlur}
        error={showError('city', true)}
      />
      <Row className="form__cols">
        <Col sm={12} className="form__col">
          <InputRow
            label={t('poBox')}
            name="postcode"
            type="text"
            value={values.postcode}
            onChange={handleChange}
            onBlur={handleBlur}
            error={showError('postcode', true)}
          />
        </Col>
      </Row>

      <FormRow type="submit">
        <Button
          htmlType="submit"
          size="large"
          type="primary"
          block
          loading={isSubmitting}
          disabled={isSubmitting || selectedCountry === ''}
        >
          {t('continue')}
        </Button>
      </FormRow>
    </Form>
  );
};

export default CompanyContactsForm;
