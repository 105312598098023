import snakeToCamel from 'utils/snakeToCamel';

const fieldsTransformer = source => (res, field) => ({
  ...res,
  [snakeToCamel(field)]: source[field],
});

export const orderSerialzier = data => {
  const fields = [
    'status',
    'reference',
    'order_number',
    'display_reference',
    'total',
    'currency',
    'tax_amount',
    'shipping_amount',
    'total_refund',
    'discount',
    'employee_name',
    'created_at',
    'plan_id',
    'user_fee',
  ];
  const result = fields.reduce(fieldsTransformer(data), {});

  const customerFields = ['first_name', 'last_name', 'email', 'phone'];
  result.customer =
    (data.customer && customerFields.reduce(fieldsTransformer(data.customer), {})) || {};
  result.id = data.order_id;

  const consumerInfoFields = ['first_name', 'last_name', 'email', 'phone'];
  result.consumerInfo =
    (data.consumer_info && consumerInfoFields.reduce(fieldsTransformer(data.consumer_info), {})) ||
    {};

  return result;
};

export const ordersListSerializer = data => {
  // const dataArray = Array.from(data);
  return data.map(orderSerialzier);
};

export default ordersListSerializer;
