/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/ar';
import Currency from 'spotii-ui/Currency';
// import { DateRangePicker } from 'components/DatePicker';
import { DRAFT_STATUS_PENDING_MERCHANT } from 'utils/constants';
import Button from 'spotii-ui/Button';
import TableView from 'components/TableView/TableView';
import Pagination from 'components/Pagination';
import DraftStatus from 'components/DraftStatus';
import DraftActions from 'components/DraftActions';
import { changePage, reset } from 'reducers/orders';
import { fetchDrafts } from 'reducers/drafts';

import './DraftsList.scss';
import Checkbox from '../../spotii-ui/Checkbox';
import { getFlag, persistFlag } from '../../utils/misc';
import Barcode from 'react-barcode';

const ORDERS_PER_PAGE = 10;
const MERCHANT_WIDE = 'merchantWide';
const ADMIN_READ = 'admin_read';
//
// Component
//
const DraftsList = ({ appState }) => {
  const dispatch = useDispatch();
  const {
    isLoading,
    isResolved,
    list,
    fromDate: stateFromDate,
    toDate: stateToDate,
    pageCount,
    currentPage,
  } = useSelector(state => state.drafts);
  const cls = b.with('drafts-list');
  const page = Math.max(currentPage, 1) - 1;
  const currLang = useSelector(state => state.language.language);
  const { currentUser, isMerchantBarcode } = useSelector(state => state.application);

  let displayMerchantWideToggle = true;
  let initMerchantWide = true;
  if (appState.loggedEmployee && appState.loggedEmployee.role) {
    const userPermissions = appState.loggedEmployee.role.permissions;
    displayMerchantWideToggle = userPermissions.includes(ADMIN_READ);
    initMerchantWide = displayMerchantWideToggle;
  }
  if (!appState.employeeLinkedQr) {
    displayMerchantWideToggle = false;
  }
  try {
    initMerchantWide = getFlag(MERCHANT_WIDE);
    // eslint-disable-next-line no-empty
  } catch (e) {}

  const [merchantWide, setMerchantWide] = useState(initMerchantWide);

  const fetchParams = {
    fromDate: stateFromDate,
    toDate: stateToDate,
    limit: ORDERS_PER_PAGE,
    page: currentPage,
    merchant: currentUser.merchant.merchantId,
    merchantWide,
  };
  const { t } = useTranslation();

  // handlers
  const onPageChange = p => dispatch(changePage(p.selected + 1));

  // Reset reducer on page view
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => dispatch(reset()), []);

  // Fetch orders on first render and if one of the listed parameters changed.
  useEffect(() => {
    dispatch(fetchDrafts(fetchParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateFromDate, stateToDate, currentPage, merchantWide]);

  // Columns render options
  const columns = [
    {
      title: <div className={`column-title-${currLang}`}>{t('date')}</div>,
      dataIndex: 'created_at',
      key: 'createdAt',
      render: value => {
        return (
          <div className={`line-height column-render-${currLang}`}>
            <div className="drafts-list__date" title={moment(value).format('YYYY-MM-DD HH:mm:ss')}>
              {currLang === 'ar'
                ? moment(value)
                    .locale('ar')
                    .format('YYYY-MM-DD')
                : moment(value).format('YYYY-MM-DD')}
            </div>
          </div>
        );
      },
    },
    {
      title: <div className={`column-title-${currLang}`}>{t('status')}</div>,
      dataIndex: 'status',
      key: 'status',
      render: value => (
        <div className={`line-height column-render-${currLang}`}>
          <DraftStatus status={value} badge />
        </div>
      ),
    },
    {
      title: <div className={`column-title-${currLang}`}>{t('reference')}</div>,
      dataIndex: 'order_number',
      key: 'order_number',
      render: (value, row) => (
        <div className={`line-height column-render-${currLang}`}>
          <div>{row.display_reference}</div>
          {isMerchantBarcode && row.display_reference && (
            <Barcode
              value={`${row.display_reference}`}
              width={1}
              height={12}
              margin={5}
              format="CODE128"
              displayValue={false}
            />
          )}
          {value ? (
            <div className="drafts-list__sub">{`${t('spotiiID')} ${value.toUpperCase()}`}</div>
          ) : null}
        </div>
      ),
    },
    {
      title: <div className={`column-title-${currLang}`}>{t('advisor')}</div>,
      dataIndex: 'employee_data',
      key: 'employee',
      render: value => {
        return (
          <div className={`line-height column-render-${currLang}`}>
            {((value || {}).info || {}).user
              ? `${value.info.user.first_name} ${value.info.user.last_name}`
              : ''}
          </div>
        );
      },
    },
    {
      title: <div className={`column-title-${currLang}`}>{t('customer')}</div>,
      dataIndex: 'consumer_detail',
      key: 'customer',
      render: value => (
        <div className={`line-height column-render-${currLang}`}>
          <div>{value.first_name ? value.user_name : value.username}</div>
          <div className="drafts-list__sub">{value.email}</div>
        </div>
      ),
    },
    {
      title: <div className={`column-title-${currLang}`}>{t('total')}</div>,
      dataIndex: 'amount',
      key: 'total',
      render: (value, row) => (
        <div className={`line-height column-render-${currLang}`}>
          <Currency currency={row.currency} amount={value} />
        </div>
      ),
    },
    {
      title: <div className={`column-title-${currLang}`}>{t('action')}</div>,
      dataIndex: '',
      key: 'actions',
      render: (value, row) => {
        return (
          <div className={`line-height column-button-${currLang}`}>
            {value.status === DRAFT_STATUS_PENDING_MERCHANT ? (
              <DraftActions
                draft={row}
                reload={() => dispatch(fetchDrafts(fetchParams))}
                appState={appState}
                currLang={currLang}
              />
            ) : (
              <div />
            )}
          </div>
        );
      },
    },
  ];

  // dates should be a MomentJS instance
  // const initialStartDate = stateFromDate ? moment(stateFromDate) : null;
  // const initialEndDate = stateToDate ? moment(stateToDate) : null;

  // const [dateFocus, setDateFocus] = useState(null);
  // const [fromDate, setFromDate] = useState(initialStartDate);
  // const [toDate, setToDate] = useState(initialEndDate);

  return (
    <div className={cls()}>
      <div className={cls('controls')}>
        <Button
          type="default"
          size="small"
          icon="recycle"
          onClick={() => dispatch(fetchDrafts(fetchParams))}
        >
          {t('getRequests')}
        </Button>
        {displayMerchantWideToggle && (
          <Checkbox
            checked={merchantWide}
            onChange={() => {
              persistFlag(MERCHANT_WIDE, !merchantWide);
              setMerchantWide(!merchantWide);
            }}
            name="merchantWide"
          >
            {t('getMerchantWideRequests')}
          </Checkbox>
        )}
        {/* <DateRangePicker
          // Allow to select only one date
          t={t}
          currLang={currLang}
          minimumNights={0}
          numberOfMonths={1}
          hideKeyboardShortcutsPanel
          startDateId="startDate"
          endDateId="endDate"
          startDate={fromDate}
          endDate={toDate}
          onDatesChange={({ startDate, endDate }) => {
            setFromDate(startDate);
            setToDate(endDate);
          }}
          focusedInput={dateFocus}
          onFocusChange={focusedInput => {
            setDateFocus(focusedInput);
          }}
          customArrowIcon="–"
          // Allow to select any date
          isOutsideRange={() => {}}
          onClose={({ startDate, endDate }) => {
            dispatch(
              changeDates({
                fromDate: startDate ? startDate.format('YYYY-MM-DD') : null,
                toDate: endDate ? endDate.format('YYYY-MM-DD') : null,
              }),
            );
          }}
        /> */}
      </div>
      <TableView columns={columns} data={list} loading={isLoading} />
      {isResolved && list.length ? (
        <div className={cls('pagination')}>
          <Pagination current={page} count={pageCount} onChange={onPageChange} />
        </div>
      ) : null}
      {/* <CustomReferenceModal
        show={showCustomReferenceModal}
        setShowCustomReferenceModal={setShowCustomReferenceModal}
      /> */}
    </div>
  );
};

DraftsList.propTypes = {
  appState: PropTypes.objectOf(PropTypes.objectt).isRequired,
};

DraftsList.defaultProps = {};

export default DraftsList;
