import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Form, { FormRow, InputRow } from 'spotii-ui/Form';
import Button from 'spotii-ui/Button';
import authApi from 'api/auth';
import { setRegistrationFields } from 'reducers/registration';
import Mailcheck from 'react-mailcheck';
import config from 'utils/config';

const Registration = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('somethingWrongEmail'))
      .required(t('emailIsRequired')),
    password: Yup.string()
      .min(8, t('passwordMin8'))
      .required(t('passwordIsRequired')),
  });

  const processFormErrors = errors => {
    const formErrors = {};

    if (typeof errors === 'object' && 'user' in errors) {
      if (errors.user.email) {
        const emailErr = errors.user.email;
        if (Array.isArray(emailErr) && emailErr[0] === 'user with this email already exists.') {
          formErrors.email = t('emailExistsForAccount');
        } else {
          formErrors.email = t('somethingWrongWithThisEmail');
        }
      }
      if (errors.user.password) {
        formErrors.password = errors.user.password.pop();
        if (formErrors.password === 'This value does not match the required pattern.') {
          formErrors.password = t('passwordShouldHave');
        }
      }
    } else {
      console.warn('Something went wrond during registration.');
    }

    return formErrors;
  };
  const formik = useFormik({
    initialValues: { email: '', password: '' },
    validationSchema,
    onSubmit: async (values, actions) => {
      try {
        const { email, password } = values;
        await authApi.registrationBegin(email, password, null, null, {
          tenantId: config('TENANT_ID'),
        });
        dispatch(setRegistrationFields({ email }));
        actions.setStatus('success');
      } catch (e) {
        const { errors } = e;
        actions.setErrors(processFormErrors(errors));
      }
    },
  });

  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    submitCount,
  } = formik;

  const handleClickMailChange = suggestion => {
    values.email = suggestion.full;
    document.getElementsByName('email')[0].focus();
  };

  if (formik.status === 'success') {
    return <Redirect to="/signup/company-contacts" />;
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Mailcheck email={values.email}>
        {suggestion => (
          <>
            <InputRow
              label={t('businessEmail')}
              name="email"
              type="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={submitCount && errors.email && touched.email ? errors.email : null}
            />
            {suggestion && (
              <div style={{ fontSize: 14, textAlign: 'right' }}>
                {t('didYouMeanEmail')}{' '}
                <Button
                  type="button"
                  style={{ color: 'red', fontSize: 14, paddingLeft: 0, paddingRight: 0 }}
                  onMouseDown={() => handleClickMailChange(suggestion)}
                >
                  {suggestion.full}
                </Button>
                {t('questionMark')}
              </div>
            )}
          </>
        )}
      </Mailcheck>
      <InputRow
        label={t('password')}
        placeholder={t('minimumEightSymbols')}
        name="password"
        type="password"
        // placeholder="(minimum 8 letters and numbers)"
        value={values.password}
        onChange={handleChange}
        onBlur={handleBlur}
        error={submitCount && errors.password && touched.password ? errors.password : null}
      />
      <FormRow type="submit">
        <Button
          htmlType="submit"
          size="large"
          type="primary"
          block
          loading={isSubmitting}
          disabled={isSubmitting}
        >
          {t('createMerchantAccount')}
        </Button>
      </FormRow>
    </Form>
  );
};

export default Registration;
