import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import Form, { FormRow, FormError, InputRow } from 'spotii-ui/Form';
import Selectbox from 'spotii-ui/Selectbox';
import Button from 'spotii-ui/Button';
import Heading from 'spotii-ui/Heading';
import authApi from 'api/auth';
import { setRegistrationFields } from 'reducers/registration';
import snakeToCamel from 'utils/snakeToCamel';
import { currencyFromAlpha2 } from '../../utils/countries';

const prepareData = values => {
  const [day, month, year] = values.licenseExpiredAt.split('/');
  // month is zero indexed in Date()
  const licenseExpiredAt = dayjs(new Date(year, month - 1, day)).format('YYYY-MM-DD');

  return {
    ...values,
    licenseExpiredAt,
  };
};

const CompanyDetailsForm = () => {
  const { t } = useTranslation();
  const validationSchema = Yup.object().shape({
    businessSector: Yup.string().required(t('selectYourBusinessSector')),
    yearlySales: Yup.string().required(t('selectYearlySales')),
    averageOrderValue: Yup.string(),
    licenseNumber: Yup.string().required(t('licenseIsRequired')),
    licenseExpiredAt: Yup.string().required(t('licenceExpirationDateIsRequired')),
    trnNumber: Yup.string(),
    websiteUrl: Yup.string()
      .url(t('thisLinkNotCorrect'))
      .required(t('provideWebsiteLink')),
    ecommercePlatform: Yup.string().required(t('selectYourWebSitePlatform')),
  });

  const processErrors = e => {
    let result = {};
    const { errors } = e;
    const processFields = obj =>
      Object.keys(obj).reduce((res, key) => {
        // Process profile as set of own errors
        if (key === 'profile') {
          return {
            ...res,
            ...processFields(obj[key]),
          };
        }

        res[snakeToCamel(key)] = Array.isArray(obj[key]) ? obj[key][0] : obj[key];
        return res;
      }, {});

    if (errors) {
      result = processFields(errors);

      if (errors.code === 'user_already_exists') {
        result.phoneNumber = t('alreadyHaveAccountWithThisPhoneNumber');
      } else if (errors.code === 'invalid_license_expired_at') {
        result.licenseExpiredAt = t('licenseAlreadyExpired');
      }
    }

    return result;
  };
  const { country } = useSelector(state => state.registration);
  const currency = currencyFromAlpha2(country);
  const dispatch = useDispatch();
  // const { userId } = useSelector(state => state.application.currentUser || {});
  const {
    businessSector,
    yearlySales,
    averageOrderValue,
    licenseNumber,
    licenseExpiredAt,
    trnNumber,
    websiteUrl,
    ecommercePlatform,
  } = useSelector(state => state.registration);

  const formik = useFormik({
    initialValues: {
      businessSector,
      yearlySales,
      averageOrderValue,
      licenseNumber,
      licenseExpiredAt,
      trnNumber,
      websiteUrl,
      ecommercePlatform,
    },
    validationSchema,
    onSubmit: async (values, actions) => {
      try {
        const data = prepareData(values);
        await authApi.registrationUpdate(data);
        // Save current fields in the memory. Phone number in international format
        dispatch(setRegistrationFields(values));
        actions.setStatus('success');
      } catch (e) {
        console.warn(e);
        actions.setErrors(processErrors(e));
      }
    },
  });

  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    submitCount,
  } = formik;

  if (formik.status === 'success') {
    return <Redirect to="/signup/personal-details" push />;
  }

  const showError = (field, afterSubmit) =>
    (!afterSubmit || submitCount) && errors[field] && touched[field] ? errors[field] : null;

  return (
    <Form onSubmit={handleSubmit}>
      <FormRow>
        <Selectbox
          placeholder={t('businessSector')}
          name="businessSector"
          value={values.businessSector}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          <option value="" disabled>
            {t('businessSector')}
          </option>
          <option>{t('apparel')}</option>
          <option>{t('beauty')}</option>
          <option>{t('fitnessAndGear')}</option>
          <option>{t('luxury')}</option>
          <option>{t('accessories')}</option>
          <option>{t('electronics')}</option>
          <option>{t('homeAndFurniture')}</option>
          <option>{t('shoes')}</option>
          <option>{t('kids')}</option>
        </Selectbox>
        {showError('businessSector', true) ? <FormError>{errors.businessSector}</FormError> : null}
      </FormRow>
      <FormRow>
        <Selectbox
          placeholder={t('yearlySales')}
          name="yearlySales"
          value={values.yearlySales}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          <option value="" disabled>
            {t('yearlySales')}
          </option>
          <option>{currency} 0 - 100,000</option>
          <option>{currency} 100,000 - 500,000</option>
          <option>{currency} 500,000 – 1,000,000</option>
          <option>{currency} 1,000,000 – 5,000,000</option>
          <option>{currency} 5,000,000 – 10,000,000</option>
          <option>{currency} 10,000,000 – 50,000,000</option>
          <option>{currency} 50,000,000 +</option>
        </Selectbox>
        {showError('yearlySales', true) ? <FormError>{errors.yearlySales}</FormError> : null}
      </FormRow>
      <InputRow
        label={t('avgOrderValue')}
        name="averageOrderValue"
        type="text"
        value={values.averageOrderValue}
        onChange={handleChange}
        onBlur={handleBlur}
        error={showError('averageOrderValue', true)}
      />

      <FormRow type="submit">
        <Heading h3>{t('legalInformation')}</Heading>
      </FormRow>

      <InputRow
        label={t('licenseNumber')}
        name="licenseNumber"
        type="text"
        value={values.licenseNumber}
        onChange={handleChange}
        onBlur={handleBlur}
        error={showError('licenseNumber', true)}
      />
      <InputRow
        label={t('licenseExpirationDate')}
        name="licenseExpiredAt"
        type="text"
        placeholder="DD/MM/YYYY"
        formatter={{ date: true, datePattern: ['d', 'm', 'Y'] }}
        value={values.licenseExpiredAt}
        onChange={handleChange}
        onBlur={handleBlur}
        error={showError('licenseExpiredAt', true)}
      />
      <InputRow
        label={t('taxNumber')}
        name="trnNumber"
        type="text"
        value={values.trnNumber}
        onChange={handleChange}
        onBlur={handleBlur}
        error={showError('trnNumber', true)}
      />

      <FormRow type="submit">
        <Heading h3>{t('onlineBusiness')}</Heading>
      </FormRow>

      <InputRow
        label={t('websiteLink')}
        placeholder="https://"
        name="websiteUrl"
        type="text"
        value={values.websiteUrl}
        onChange={handleChange}
        onBlur={handleBlur}
        error={showError('websiteUrl', true)}
      />
      <FormRow>
        <Selectbox
          placeholder={t('eCommercePlatform')}
          name="ecommercePlatform"
          value={values.ecommercePlatform}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          <option value="" disabled>
            {t('eCommercePlatform')}
          </option>
          <option>WooCommerce</option>
          <option>Magento</option>
          <option>Shopify</option>
          <option>OpenCart</option>
          <option>SalesForce</option>
          <option>Hybris</option>
          <option>Ecwid</option>
          <option>Wix</option>
          <option>In-store</option>
          <option>Custom</option>
          <option>I’m not sure</option>
        </Selectbox>
        {showError('ecommercePlatform', true) ? (
          <FormError>{errors.ecommercePlatform}</FormError>
        ) : null}
      </FormRow>

      <FormRow type="submit">
        <Button
          htmlType="submit"
          size="large"
          type="primary"
          block
          loading={isSubmitting}
          disabled={isSubmitting}
        >
          {t('continue')}
        </Button>
      </FormRow>
    </Form>
  );
};
export default CompanyDetailsForm;
