/* eslint-disable react/display-name */
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import TableView from 'components/TableView/TableView';
import Pagination from 'components/Pagination';
import { fetchEmployees, changePage, reset } from 'reducers/employees';
import { fetchRoles } from 'reducers/roles';
import Button from 'spotii-ui/Button';
import Icon from 'spotii-ui/Icon';
import Input from 'spotii-ui/Input';
import message from 'spotii-ui/Message';

import { updateEmployeeRole, updateEmployeeState } from 'api';
import 'bootstrap/dist/css/bootstrap.min.css';
import { EMPLOYEE_COLUMNS } from '../../utils/constants';

import './EmployeeList.scss'

//Temporary fix to disable IT user adding admin roles
export const itRoles = ['IT', 'it', 'I.T.', 'I.T', 'It'];
export const adminRoles = ['Admin', 'ADMIN', 'admin'];
//
// Component
//
const EmployeeList = ({ appState }) => {
  const [search, setSearch] = useState('');
  const [activeStateChanging, setActiveStateChanging] = useState(false);

  let roleList = useSelector(state => state.roles).list || [];
  const { permissions: userPermissions = [], display_name } = appState.loggedEmployee.role || {};
  // Filter to not include admin role if currentUser not admin
  // The primary merchant account has role null; hence allow
  // console.log(appState.loggedEmployee);
  const isCurrentUserItRole = itRoles.includes(display_name);
  if (isCurrentUserItRole || (userPermissions.length && !userPermissions.includes('admin_read'))) {
    roleList = roleList.filter(({ displayName }) => !adminRoles.includes(displayName));
  }

  const dispatch = useDispatch();
  const fetchParamsRoles = {
    merchantId: appState.currentUser.merchant.merchantId,
  };
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(fetchRoles(fetchParamsRoles));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { isLoading, isResolved, list, pageCount, currentPage } = useSelector(
    state => state.employees,
  );
  const page = Math.max(currentPage, 1) - 1;
  const fetchParams = {
    limit: 10,
    page: currentPage,
    merchantId: appState.currentUser.merchant.merchantId,
  };

  // handlers
  const onPageChange = p => dispatch(changePage(p.selected + 1));

  // Add Actions column to the table view
  const columnsExtended = EMPLOYEE_COLUMNS.slice();
  const selectOptions = roleList.map(role => {
    return { value: role.roleId, label: role.displayName };
  });

  const onRolechange = (userId, event) => {
    updateEmployeeRole({
      role_id: event.value,
      user_id: userId,
      merchant_id: appState.currentUser.merchant.merchantId,
    }).then(() => message.success(t('roleSuccessfullyAttributed')));
  };

  const onEmployeeStatechange = (userId, newState) => {
    setActiveStateChanging(true);
    updateEmployeeState({
      is_active: newState,
      user_id: userId,
      merchant_id: appState.currentUser.merchant.merchantId,
    })
      .then(() => {
        setActiveStateChanging(false);
        dispatch(reset());
        dispatch(fetchEmployees(fetchParams));
        message.success(`Login has been ${newState ? 'enabled' : 'disabled'} for user`);
      })
      .catch(() => {
        setActiveStateChanging(false);
        message.error(`Failed to change login state`);
      });
  };
  const { currentUser } = useSelector(state => state.application);
  columnsExtended.push(
    {
      title: 'Role',
      key: 'role',
      render: value => {
        if (roleList.length > 0 && (!isCurrentUserItRole || !adminRoles.includes(value.role))) {
          return (
            <span id="roleSelectContainer">
              {value.role ?
              <Select
                options={selectOptions}
                placeholder={value.role}
                onChange={e => {
                  onRolechange(value.employee_id, e);
                }}
                controlShouldRenderValue
              /> : null }
            </span>
          );
        }
        if (roleList.length === 0) {
          return <Link to="/admin/addRole">Create roles</Link>;
        }
      },
    },
    {
      title: 'Login allowed',
      key: 'isActive',
      render: value => {
        return currentUser.userId !== value.employee_id && (!isCurrentUserItRole || !adminRoles.includes(value.role)) ? (
          <span>
            <Button
              type="link"
              disabled={activeStateChanging}
              onClick={() => {
                onEmployeeStatechange(value.employee_id, !value.is_active);
              }}
            >
              {value.is_active ? 'Deactivate' : 'Activate'}
            </Button>
          </span>
        ) : (
          ''
        );
      },
    },
  );

  // Reseth reducer on page view
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => dispatch(reset()), []);

  const newSearch = search => {
    dispatch(fetchEmployees({ ...fetchParams, search }));
  };
  useEffect(() => {
    dispatch(fetchEmployees(fetchParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);


  const handleChange = e => {
    const value = e.target.value;
    setSearch(value);
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      newSearch(search);
    }
  };
  return (
    <div>
      <Row>
        <Col md={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ flexGrow: 2 }}>
            <Input
              name="search"
              label={
                <span>
                  <Icon type={'search'} />
                  Search
                </span>
              }
              placeholder={t('searchEmployees')}
              value={search}
              style={{ marginBottom: '15px' }}
              onChange={handleChange}
              onBlur={e => newSearch(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </div>
          <Button type="link" to="/admin/addEmployee" style={{ marginBottom: '15px' }}>
            Add employee <Icon type="new" />
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <TableView
            columns={columnsExtended}
            data={list}
            loading={isLoading}
            rowKey="employee_id"
          />
          {isResolved && list.length ? (
            <div>
              <Pagination current={page} count={pageCount} onChange={onPageChange} />
            </div>
          ) : null}
        </Col>
      </Row>
    </div>
  );
};
EmployeeList.propTypes = {
  appState: PropTypes.objectOf(PropTypes.object).isRequired,
};
export default EmployeeList;
