import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import b from 'b_';
import { useFormik } from 'formik';
import { Redirect } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import Form, { FormRow, InputRow, FormError } from 'spotii-ui/Form';
import Button from 'spotii-ui/Button';
import message from 'spotii-ui/Message';
import { addBankAccount } from 'api';
import Select from 'react-select';
import { COUNTRY_SELECT_OPTIONS, NO_IBAN_COUNTRIES } from 'utils/constants';

import isValidIBANNumber from './form/validation';
import formSerializer from './form/serializer';
import errorsSerializer from './form/errors';

const AddressForm = ({ continueTo, onSuccess }) => {
  const { t } = useTranslation();
  const [selectedCountry, setSelectedCountry] = useState(null);

  const validate = values => {
    const errors = {};
    const swiftRegEx = /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/i;
    const requiredFields = {
      bank: t('bankNameRequired'),
      accountNumber: t('accountNumberRequired'),
      accountTitle: t('accountTitleRequired'),
      swift: t('swiftRequired'),
      country: t('countryRequired'),
    };

    if (!NO_IBAN_COUNTRIES.includes(selectedCountry)) {
      requiredFields.iban = t('ibanRequired');
    }

    Object.keys(requiredFields).forEach(key => {
      if (!values[key]) {
        errors[key] = requiredFields[key];
      }
    });

    if (
      !NO_IBAN_COUNTRIES.includes(selectedCountry) &&
      !errors.iban &&
      isValidIBANNumber(values.iban) !== 1
    ) {
      errors.iban = t('ibanNotValid');
    }

    if (!errors.swift && !swiftRegEx.test(values.swift)) {
      errors.swift = t('swiftNotValid');
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      bank: '',
      accountNumber: '',
      accountTitle: '',
      iban: '',
      swift: '',
      country: '',
    },
    validate,
    // validationSchema,
    onSubmit: async (values, actions) => {
      const data = formSerializer(values);
      actions.setStatus();

      try {
        await addBankAccount(data);
        actions.setStatus('success');
        message.success(t('newBankAccountAddedSuccess'));
        if (onSuccess) {
          onSuccess(data);
        }
      } catch (e) {
        console.warn(e);
        const errors = errorsSerializer(e.errors);
        if (errors) {
          actions.setErrors(errors);
        } else {
          message.error(
            <span>
              {`${t('formErrorTryAgainContact')} `}
              <a href="mailto:">merchantsupport@spotii.me</a>
            </span>,
          );
          actions.setStatus('error');
        }
      }
    },
  });

  const { values, touched, errors, handleBlur, isSubmitting, handleChange, setFieldValue } = formik;

  const onCountrySelectChange = e => {
    setSelectedCountry(e.value);
    setFieldValue('country', e.value);
    if (NO_IBAN_COUNTRIES.includes(selectedCountry)) {
      setFieldValue('iban', '');
    }
  };

  if (formik.status === 'success' && continueTo) {
    return <Redirect to={continueTo} push />;
  }

  if (formik.status === 'error') {
    // Show generic error message
  }

  const getError = key => touched[key] && errors[key];

  return (
    <div className={b('address-form')}>
      <Form onSubmit={formik.handleSubmit}>
        <Row className="form__cols" style={{ marginTop: '17px' }}>
          <Col sm={12} md={12} className="form__col" id="countrySelectContainer">
            <Select
              options={COUNTRY_SELECT_OPTIONS}
              placeholder={t('selectBankCountry')}
              onChange={onCountrySelectChange}
              controlShouldRenderValue
            />
            {errors.country && touched.country ? <FormError>{errors.country}</FormError> : null}
          </Col>
        </Row>
        <InputRow
          name="bank"
          label={t('bankName')}
          type="text"
          value={values.bank}
          onChange={handleChange}
          onBlur={handleBlur}
          error={getError('bank')}
        />
        <InputRow
          name="accountNumber"
          label={t('accountNumber')}
          type="text"
          value={values.accountNumber}
          onChange={handleChange}
          onBlur={handleBlur}
          error={getError('accountNumber')}
        />
        <InputRow
          name="accountTitle"
          label={t('accountTitle')}
          type="text"
          value={values.accountTitle}
          onChange={handleChange}
          onBlur={handleBlur}
          error={getError('accountTitle')}
        />
        {!NO_IBAN_COUNTRIES.includes(selectedCountry) && (
          <InputRow
            name="iban"
            label={t('iban')}
            type="text"
            value={values.iban}
            onChange={handleChange}
            onBlur={handleBlur}
            error={getError('iban')}
          />
        )}
        <InputRow
          name="swift"
          label={t('swift')}
          type="text"
          value={values.swift}
          onChange={handleChange}
          onBlur={handleBlur}
          error={getError('swift')}
        />

        <FormRow type="submit">
          <Button
            htmlType="submit"
            size="large"
            type="primary"
            block
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            {t('addBankAccount')}
          </Button>
        </FormRow>
      </Form>
    </div>
  );
};

AddressForm.propTypes = {
  continueTo: PropTypes.string,
  onSuccess: PropTypes.func,
  prefill: PropTypes.shape({
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    zip: PropTypes.string,
  }),
};

AddressForm.defaultProps = {
  continueTo: null,
  onSuccess: null,
  prefill: null,
};

export default AddressForm;
