const normProp = prop => prop && prop.trim();

const fields = [
  'email',
  'password',
  'first_name',
  'last_name',
  'date_of_birth',
  'phone_number',
  'role',
  'role_id',
];

const serializer = values => ({
  ...fields.reduce(
    (res, key) => ({
      ...res,
      [key]: normProp(values[key]),
    }),
    {},
  ),
});

export default serializer;
